exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-agb-tsx": () => import("./../../../src/pages/agb.tsx" /* webpackChunkName: "component---src-pages-agb-tsx" */),
  "component---src-pages-angebote-tsx": () => import("./../../../src/pages/angebote.tsx" /* webpackChunkName: "component---src-pages-angebote-tsx" */),
  "component---src-pages-experten-tsx": () => import("./../../../src/pages/experten.tsx" /* webpackChunkName: "component---src-pages-experten-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-standard-formblatt-tsx": () => import("./../../../src/pages/standard-formblatt.tsx" /* webpackChunkName: "component---src-pages-standard-formblatt-tsx" */),
  "component---src-pages-standorte-tsx": () => import("./../../../src/pages/standorte.tsx" /* webpackChunkName: "component---src-pages-standorte-tsx" */),
  "component---src-pages-veranstalter-agb-tsx": () => import("./../../../src/pages/veranstalter-agb.tsx" /* webpackChunkName: "component---src-pages-veranstalter-agb-tsx" */),
  "component---src-templates-experte-tsx": () => import("./../../../src/templates/experte.tsx" /* webpackChunkName: "component---src-templates-experte-tsx" */)
}

